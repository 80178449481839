//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';


.loader-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 100px 0px;

    img{
        width: 100px;
        height: auto;
    }

    span{
        font-size: 16px;
        margin-top: 5px;
    }
}

.table-data-img{
    border: 1px solid rgba(128, 128, 128, 0.295);
}

.disable-link{
    pointer-events: none;
}